import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"

import { getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  color: Color
  open: boolean
}

const AccordionPlusIcon = ({ color, open }: Props) => {
  return (
    <div
      className={classNames(
        "relative h-24 w-24 transition-transform duration-300",
        { "rotate-90": open },
        { "rotate-0": !open }
      )}
    >
      <span
        className={classNames(
          getBackgroundColorClass(color),
          "absolute left-1/2 top-1/2 h-[1.68px] w-12 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300",
          { "opacity-100": !open },
          { "opacity-0": open }
        )}
      />
      <span
        className={classNames(
          getBackgroundColorClass(color),
          "absolute left-1/2 top-1/2 h-12 w-[1.68px] -translate-x-1/2 -translate-y-1/2"
        )}
      />
    </div>
  )
}

export default AccordionPlusIcon
