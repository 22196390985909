import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import FAQSection from "../../../V2/Sections/Faq"

import { getUrlFromStoryblokLink } from "@utils/storyblok"

const StoryblokFAQSection = ({
  blok,
}: Storyblok.BlokProps<Storyblok.FAQSection>) => {
  return (
    <FAQSection
      title={blok.headerText}
      body={blok.bodyText}
      items={blok.faqItems}
      linkColor={blok.ctaColor}
      linkText={blok.ctaText}
      linkUrl={getUrlFromStoryblokLink(blok.ctaLink)}
      textColor={blok.textColor}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokFAQSection
